import './App.css';
import EmailForm from './EmailForm';


function App() {
  return (

    <div id="container">
      <h1>Crimson Bicycles</h1>
      <img src="logo.jpg" width="100%" alt="Crimson Bicycles" />

      <h2>Join our mailing list</h2>
      <EmailForm />
    </div>

  );
}

export default App;
